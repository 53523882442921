import React from 'react';

const MyServices = props => {
    return (
        <React.Fragment>
            Services
        </React.Fragment>
    )
}

export default MyServices;